/* Made with love by Mutiullah Samim*/

@import url('https://fonts.googleapis.com/css?family=Numans');

#containerLogin {
/*background-image: url('https://getwallpapers.com/wallpaper/full/8/2/2/575638.jpg');*/
background-image: url('/images/background.png');
background-size: cover;
background-repeat: no-repeat;
background-position: center;
height: 100%;
width: 100%;
font-family: 'Numans', sans-serif;
font-size: 0.8rem;
}

#innerContainer {
    width: 80%;
    margin: 0 auto;
}

.loading {
    width: 50px;
    margin: auto;
}

  
#phoneIcon {
    padding:15px;
}

#header {
    margin-top: 50px;
}

#container_error {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    margin-top: 10px;
}

#error_message {
    color:#FFC312;
    font-weight: 800;
}

#success_message {
    color:#FFC312;
    font-weight: 800;
}

#fields {
    width: 80%;
    margin: 0 auto;
}

#msisdn {
    width: 60%;
    text-align: center;
}

#logindiv {
    width: 50%;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
}

.container{
height: 100%;
align-content: center;
}

.card{
height: 370px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
background-color: rgba(0,0,0,0.5) !important;
}

.social_icon span{
font-size: 60px;
margin-left: 10px;
color: #FFC312;
}

.social_icon span:hover{
color: white;
cursor: pointer;
}

.card-header h3{
color: white;
text-align: center;
}

.social_icon{
position: absolute;
right: 20px;
top: -45px;
}

.input-group-prepend span{
width: 50px;
background-color: #FFC312;
color: black;
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;

}

.remember{
color: white;
}

.remember input
{
width: 20px;
height: 20px;
margin-left: 15px;
margin-right: 5px;
}

.login_btn{
color: black;
background-color: #FFC312;
width: 100px;
}

.login_btn:hover{
color: black;
background-color: white;
}

.links{
color: white;
}

.links a{
margin-left: 4px;
}